<template>
  <div class="line-chart" id="line"></div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { Line } from '@antv/g2plot'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lineChart: null,
      options: {},
    }
  },
  methods: {
    setCharts(data) {
      if (this.lineChart) this.lineChart.destroy()
      this.options = {
        data,
        forceFit: true,
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        height: 300,
        animation: {
          appear: {
            animation: 'clipingWithData',
          },
        },
        legend: {
          position: 'top-left',
          offsetY: 10,
        },
        padding: [80, 50, 50, 50],
      }
      this.lineChart = new Line('line', this.options)
      this.lineChart.render()
      console.log(this.lineChart)
    },
  },
  watch: {
    // data(newVal, oldVal) {
    // 	console.log(newVal.length);
    // 	if (newVal.length) this.setCharts()
    // }
  },
}
</script>

<style lang="scss" scoped></style>
