<template>
  <div class="layout">
    <!-- 数据统计 -->
    <el-card class="card" shadow="never">
      <template #header>
        <div class="card-header">
          <span class="title">数据统计（累计至昨日）</span>
        </div>
      </template>
      <el-row class="card statistics" justify="space-around">
        <el-col :span="4" class="item" @click="toPage('/user/list')">
          <p>{{ mulStatistics.member_total_count || 0 }}</p>
          <p>累计用户数量</p>
        </el-col>
        <el-col :span="4" class="item" @click="toPage('/merchant/list')">
          <p>{{ mulStatistics.bus_total_count || 0 }}</p>
          <p>累计商家数量</p>
        </el-col>
        <el-col :span="4" class="item" @click="toPage('/statistics/send-record')">
          <p>{{ mulStatistics.send_coupon_count || 0 }}</p>
          <p>累计发放优惠券数量</p>
        </el-col>
        <el-col :span="4" class="item" @click="toPage('/statistics/use-record')">
          <p>{{ mulStatistics.used_coupon_count || 0 }}</p>
          <p>累计核销数量</p>
        </el-col>
        <el-col :span="4" class="item" @click="toPage('/statistics/use-record')">
          <p v-if="mulStatistics.order_total_at && mulStatistics.order_discount_total_at">
            ￥{{ (Number(mulStatistics.order_total_at) - Number(mulStatistics.order_discount_total_at)).toFixed(2) }}
          </p>
          <p v-else>0</p>
          <p>累计用户消费总额</p>
        </el-col>
      </el-row>
    </el-card>
    <el-tabs class="card activity" type="border-card" v-if="onGoingList.length || onEndingList.length">
      <el-tab-pane label="进行中的活动" v-if="onGoingList.length">
        <div class="item" v-for="item in onGoingList" :key="item.id">
          <div class="coupon-name" @click="toPage('/activity/list', { id: item.up_coupon_id })">
            <i class="iconfont icon-default"></i>{{ item.name }}
          </div>
          <div class="ml-50">{{ item.start_time }} - {{ item.end_time }}</div>
          <div class="ml-20">优惠券总数：{{ item.grant_nums }}</div>
          <div class="ml-20">剩余：{{ item.remaining_nums }}</div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="即将结束的活动" v-if="onEndingList.length">
        <div class="item" v-for="item in onEndingList" :key="item.id">
          <div class="coupon-name" @click="toPage('/activity/list', { id: item.up_coupon_id })">
            <i class="iconfont icon-default"></i>{{ item.name }}
          </div>
          <div class="ml-50">{{ item.start_time }} - {{ item.end_time }}</div>
          <div class="ml-20">优惠券总数：{{ item.grant_nums }}</div>
          <div class="ml-20">剩余：{{ item.remaining_nums }}</div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 图表 -->
    <el-card shadow="never" class="card">
      <template #header>
        <div class="card-header">
          <div class="flex-between-align">
            <div class="title">用户核销数量统计</div>
            <div class="flex-align">
              <el-tag
                class="tag"
                :effect="item.effect"
                v-for="item in dateOptions"
                :key="item.value"
                @click="chooseDate(item)"
                >{{ item.label }}</el-tag
              >
            </div>
          </div>
        </div>
      </template>
      <!-- 折线图 -->
      <line-chart ref="lineChart" :data="graphStatistics"></line-chart>
    </el-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import statisticsModel from '@/model/statistics'
import lineChart from './components/line-chart.vue'

export default {
  components: {
    lineChart,
  },
  setup() {
    const router = useRouter()
    const dateOptions = ref([
      {
        label: '近七天',
        value: 7,
        effect: 'dark',
        dateVal: [],
      },
      {
        label: '近三十天',
        value: '30',
        effect: 'plain',
        dateVal: [],
      },
    ])
    const days = ref(7)
    const mulStatistics = ref({})
    const onGoingList = ref([])
    const onEndingList = ref([])
    const graphStatistics = ref([])
    const lineChart = ref(null)

    const initData = () => {
      getMultipleStatistics()
      getOnGoingActivity()
      getOnEndingActivity()
      getGraphStatistics()
    }

    // 获取累计统计数据
    const getMultipleStatistics = async () => {
      const res = await statisticsModel.getMultipleStatistics()
      mulStatistics.value = res
    }

    // 获取进行中的活动列表
    const getOnGoingActivity = async () => {
      const res = await statisticsModel.getOnGoingActivity()
      onGoingList.value = res
    }

    // 获取即将结束的活动列表
    const getOnEndingActivity = async () => {
      const res = await statisticsModel.getOnEndingActivity()
      onEndingList.value = res
    }

    // 获取曲线图数据统计
    const getGraphStatistics = async () => {
      const res = await statisticsModel.getGraphStatistics({
        days: days.value,
      })
      formatChartData(res)
    }

    // 处理图表数据
    const formatChartData = data => {
      const formatData = []
      Object.keys(data).forEach(key => {
        formatData.push({
          date: key,
          value: data[key].send_total_num,
          type: '发放记录',
        })
        formatData.push({
          date: key,
          value: data[key].used_total_num,
          type: '核销记录',
        })
      })
      console.log(formatData)
      graphStatistics.value = formatData
      lineChart.value.setCharts(formatData)
    }

    // 选择日期选项
    const chooseDate = item => {
      if (item.value == days.value) return
      clearDateOptions()
      item.effect = 'dark'
      days.value = item.value
      getGraphStatistics()
    }

    // 清空日期选项
    const clearDateOptions = () => {
      dateOptions.value.forEach(i => {
        i.effect = 'plain'
      })
    }

    // 选择日期选择器
    // const chooseDatePicker = () => {
    //     let idx = dateOptions.value.findIndex(item => {
    //         item.dateVal == date.value
    //     })
    //     if (idx > -1) dateOptions.value[idx].effect = 'dark'
    //     else clearDateOptions()
    // }

    // 跳转
    const toPage = (path, query) => {
      console.log(query)
      router.push({
        path,
        query,
      })
    }

    onMounted(() => {
      initData()
    })

    return {
      dateOptions,
      days,
      mulStatistics,
      onGoingList,
      onEndingList,
      graphStatistics,
      chooseDate,
      clearDateOptions,
      getMultipleStatistics,
      getOnGoingActivity,
      getOnEndingActivity,
      getGraphStatistics,
      toPage,
      lineChart,
    }
  },
}
</script>

<style lang="scss" scoped>
.layout {
  padding: 30px;
  .title {
    font-weight: bold;
  }
  .card {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .statistics {
    .item {
      cursor: pointer;
      text-align: center;
      :nth-child(1) {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
  .activity {
    .item {
      @include flex-align;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .coupon-name {
        color: $theme;
        cursor: pointer;
        min-width: 30%;
      }
    }
  }
  .tag {
    cursor: pointer;
    margin-right: 15px;
  }
}
::v-deep .el-tabs__content {
  max-height: 125px;
  overflow: auto;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #000;
  font-weight: bold;
  font-size: 100%;
  height: 50px;
  line-height: 50px;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background: #f9fafb;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 20px;
}
</style>
